import React, { useEffect, useState } from 'react';
import './Contact.scss'
import axios from 'axios'

const Contact = (props) => {
    const [address, setAddress] = useState('');
    const [salesNo, setSalesNo] = useState('');
    const [tollfreeNo, setTollfreeNo] = useState('');
    const [salesLabel, setSalesLabel] = useState('');
    const [tollfreeLabel, setTollfreeLabel] = useState('');
    const [email, setEmail] = useState('');

    useEffect(() => {
        let unmounted = false;
        
        axios.get("https://www.telepoll.net/wp/wp-json/wp/v2/theme_option")
        .then(res => {
            if (!unmounted) {
                setAddress(res.data[0].acf['adress'])
                setSalesNo(res.data[0].acf['sales_contact_number'])
                setTollfreeNo(res.data[0].acf['toll_free_contact_number'])
                setSalesLabel(res.data[0].acf['sales_contact_number_label'])
                setTollfreeLabel(res.data[0].acf['toll_free_contact_number_label'])
                setEmail(res.data[0].acf['email_id'])
            }
        })
        .catch(err => {
            if (!unmounted) {
                console.log(err);
              }
            }
        );
        return () => { unmounted = true };
    }, [])

    return ( 
        <div className="Contact">
            <div className="container">
                <div className="map">
                    <p>{address}</p>
                </div>
                <div className="phone">
                    <p>{salesLabel}: <a href={`tel:${salesNo}`}>{salesNo}</a></p>
                    <p>{tollfreeLabel}: <a href={`tel:${tollfreeNo}`}>{tollfreeNo}</a></p>
                </div>
                <div className="mail">
                    <p><a href={`mailto:${email}`}>{email}</a></p>
                </div>
            </div>
        </div>
     );
}
 
export default Contact;