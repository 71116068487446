import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import { Route } from 'react-router-dom';
import axios from 'axios';
import Home from '../components/Home/Home';
import Privacy from '../components/Privacy/Privacy';
import Terms from '../components/Terms/Terms';
import Header from '../components/MobileMenu/MobileMenu';

const Main = (props) => {
    const [privacyTitle, setPrivacyTitle] = useState('');
    const [privacyContent, setPrivacyContent] = useState('');
    const [termsTitle, setTermsTitle] = useState('');
    const [termsContent, setTermsContent] = useState('');

    useEffect(() => {
        axios.get("https://www.telepoll.net/wp/wp-json/wp/v2/theme_option")
        .then(res => {
            setPrivacyTitle(res.data[0].acf['pppage_title']);
            setPrivacyContent(res.data[0].acf['pppage_content']);
            setTermsTitle(res.data[0].acf['tcpage_title']);
            setTermsContent(res.data[0].acf['tcpage_content']);
        })
        .catch(err =>
            console.log(err)
        );
    }, [])

    let routes = (
            <Layout>
                <Route path='/' exact component={Home} />
                <Route path='/privacy-policy' exact component={() => <Privacy title={privacyTitle} content={privacyContent} />} />
                <Route path='/terms-and-conditions' exact component={() => <Terms title={termsTitle} content={termsContent} />} />
            </Layout>
        )

    return ( 
        <React.Fragment>
        <Header />
            {routes}
        </React.Fragment>
     );
}

export default Main;    