import React from 'react';
import './Banner.scss';
import axios from 'axios';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import video from '../../assets/videos/video-bg5.mp4';

class Banner extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        pos: '-1500px',
        right: '-2500px',
        blue_text: '',
        grey_text: '',
        phone_text: '',
        data_text: '',
        phone_bg: '',
        data_bg: ''
      }
    }

    componentDidMount() {
      axios.get("https://www.telepoll.net/wp/wp-json/wp/v2/theme_option")
      .then(res => {
        this.setState({
          blue_text: res.data[0].acf['banner_blue_text'],
          grey_text: res.data[0].acf['banner_grey_text'],
          phone_text: res.data[0].acf['banner_ph_text'],
          data_text: res.data[0].acf['banner_data_text'],
          phone_bg: res.data[0].acf['banner_phone_image'].url,
          data_bg: res.data[0].acf['data_collection_image'].url
        })
      })
      .catch(err =>
        console.log(err)
      );

      // if(window.innerWidth > 1300) {
      //   setTimeout(() => {
      //     this.setState({
      //       pos: '7%',
      //       right: '-15%'
      //     })
      //   }, 4000);
      // }
      // else {
      //   this.setState({
      //     pos: '7%',
      //     right: '-15%'
      //   })
      // }
    }

    render() {
      //Slider settings
      const settings = {
        dots: false,
        arrows: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        speed: 1000,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 4500,
        pauseOnHover:false,
        responsive: [
          {
            breakpoint: 1300,
            settings: {
              autoplay: true,
              autoplaySpeed: 2000
            }
          }
        ]
      };
    return ( 
      <div className="Banner">
        <Slider {...settings} ref={slider => this.slider = slider }>
          <div>
            <div className="bannerSlider slider1">
              <div className="videoBgContainer">
                <video autoPlay muted className="bannerVideo">
                  <source src={video} type="video/mp4" />
                </video>
              </div>
              <div className="container">
                <div className="bannerContent">
                  <h3> {this.state.blue_text} <br/> <span> {this.state.grey_text} </span></h3>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="bannerSlider slider2" style={{backgroundImage: `url(${this.state.phone_bg})`}}>
              <div className="container">
                  <h3>{this.state.phone_text}</h3>
              </div>
            </div>
          </div>
          <div>
            <div className="bannerSlider slider3" style={{backgroundImage: `url(${this.state.data_bg})`}}>
              <div className="container">
                <h3>{this.state.data_text}</h3>
              </div>
            </div>
          </div>
        </Slider>
      </div>
     );
    }
}
 
export default Banner;