export const ONCLICKINITIAL = 'ONCLICKINITIAL';
export const ONCLICKLATER = 'ONCLICKLATER';
export const ONCLICKWHO = 'ONCLICKWHO';
export const ONCLICKWHAT = 'ATNCLICKWHO';
export const ONCLICKHOW = 'ONCLICKHOW';
export const INIT = 'INIT';
export const ONCLICKSERVICES = 'ONCLICKSERVICES';
export const ONCLICKCASES = 'ONCLICKCASES';
export const ONCLICKVIDEOS = 'ONCLICKVIDEOS';
export const SETDISPLAYIN = 'SETDISPLAYIN';
export const SETDISPLAYOUT = 'SETDISPLAYOUT';