import React from 'react';

const Layout = (props) => {
    return ( 
        <React.Fragment>
            {props.children}
        </React.Fragment>
     );
}

export default Layout;