import React, { useEffect, useState } from 'react';
import './Testimonial.scss';
import axios from 'axios';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonial = (props) => {
    const [ testimonials, setTestimonials ] = useState([]);
    const [ height, setHeight ] = useState('');

    useEffect(() => {
        axios.get("https://www.telepoll.net/wp/wp-json/wp/v2/theme_option")
        .then(res => {
            setTestimonials(res.data[0].acf['testimonial_slider'])
        })
        .catch(err =>
            console.log(err)
        );
    }, [])

    useEffect(() => {
        const ht = document.getElementsByClassName('testimonialSlider').clientHeight;
        setHeight({ ht });
    }, [])

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 3000,
        adaptiveHeight: true
      };
    return ( 
        <div className="Testimonial">
            <h2>Testimonials</h2>
            <Slider {...settings}>
                {testimonials.map((element, index) => 
                    <div key={index} className="testimonialSlider" style={{height: height}}>
                        <div className="container">
                            <div className="testimonialSliderContent">
                                <p>{element.quote}</p>
                                <p id="quote-company">- {element.company}</p>
                            </div>
                        </div>
                    </div>
                )}
            </Slider>
        </div>
     );
}
 
export default Testimonial;