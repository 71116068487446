import React, { useState, useRef } from 'react';
import './Service.scss';
import pdf from '../../assets/images/pdf.png';
import videoPlaceholder from '../../assets/images/video.jpg';
import Tilt from '../TiltComponent/Tilt';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "video-react/dist/video-react.css";
import { Player, BigPlayButton } from 'video-react';
import {connect } from 'react-redux'; 
import * as actions from '../../store/actions/actions';

const options = {
    max: 10,
    perspective: 1000,
    scale: 1,
  }

const Service = (props) => {

    const customSlider = useRef();
    // const scrollRef = useRef(null);

    const titles = ['SERVICES', 'CASES', 'VIDEOS'];
    const [index, setIndex] = useState('1');
    const [videoIndex, setVideoIndex] = useState('1');
    const [casesIndex, setCasesIndex] = useState('1');
    const [visibility, setVisibility] = useState('hidden');
    const [popupOpacity, setPopupOpacity] = useState('0');
    const [zIndex, setZIndex] = useState('0');

    const onClickServices = () => {
        props.onClick()
        props.onClickServices()
        setTimeout(() => {
            props.onClickLater()
            props.setDisplayIn()
          }, 1000);
        setIndex('1');
        customSlider.current.slickGoTo(0);
    }

    const myRef = useRef(null);
    // const executeScroll = () => ; 

    const executeScroll = () => myRef.current.scrollIntoView()    

    const onClickCases = () => {
        // scrollRef.current.scrollTo(0, 0);
        // ref.current.scrollIntoView({behavior: 'smooth'})
        // myRef.current.scrollIntoView({
        //     behavior: 'smooth'
        // })
        // myRef.current.scrollIntoView() 

        // myRef.current.scrollTo(0, 0);

        // const whatIDo = document.getElementById('serviceFloatingContent');
        // whatIDo.scrollIntoView();

        // executeScroll()
        props.onClick()
        props.onClickCases()
        setTimeout(() => {
            props.onClickLater()
            props.setDisplayIn()
          }, 1000);
        setCasesIndex('1')
        customSlider.current.slickGoTo(0);
    }

    const onClickVideos = () => {
        props.onClick()
        props.setDisplayOut()
        props.onClickVideos()
        setTimeout(() => {
            props.onClickLater()
          }, 1000);
        setVideoIndex('1')
    }

    const popupHandler = () => {
        setVisibility('visible');
        setZIndex('100');
        setPopupOpacity('1');
    }

    const popupCloseHandler = () => {
        setVisibility('hidden');
        setZIndex('0');
        setPopupOpacity('0');
    }

    let sliderElements = [];

    if(props.title !== titles[2])
        {props.title === titles[0] ?  sliderElements = props.desc : sliderElements = props.descCases}
    else
        sliderElements = props.videos;

    //Slider settings
    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        // swipeToSlide: true,
        // fade: true,
        speed: 800,
        arrows: false,
        afterChange: (c, next) => {
             setIndex(c+1);
        }
    };

    const settingsCases = {
        dots: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        // fade: true,
        speed: 800,
        arrows: false,
        afterChange: (current, next) => {
            setCasesIndex(current+1);
        }
    };

    const settings1 = {
        dots: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        speed: 800,
        arrows: false,
        afterChange: (current, next) => {
            setVideoIndex(current+1);
        }
    };

    let content;
    props.title === titles[2] ? content = (
        <div className="videoContainer"><Slider {...settings1} ref={slider => (customSlider.current = slider)}>
            {sliderElements.map((element, index) => (
                <div className="serviceVideo" key={index}>
                    <Player
                        playsInline
                        poster={videoPlaceholder}
                        src={element.video}
                    >
                        <BigPlayButton position="center" />
                    </Player>
                </div>
            ))}
        </Slider>
        <div className="sliderVideoIndex">
            <div className="sliderIndexContent">
                <button type="button" data-role="none" className="slick-button slicks-prev" onClick={() => customSlider.current.slickPrev()}> Prev</button>
                <p>0{videoIndex} / </p> <p style={{color: '#000'}}>0{sliderElements.length}</p>
                <button type="button" data-role="none" className="slick-button slicks-next" onClick={() => customSlider.current.slickNext()}> Next</button>
            </div>
        </div> </div>
        ) : content = (
        <Tilt
            options={options}
        >
        {props.title === titles[1] ? 
                <div className="caseFloating scrollable">
                    <>
                        <div className="caseFloatingContent" id="caseFloatingContent" ref={myRef}>
                            <Slider {...settingsCases} ref={slider => (customSlider.current = slider)}>
                            {sliderElements.map((element, i) => 
                                <div key={i} className="case">
                                    <a href={element.case_content_pdf} target="_blank">
                                        <img src={pdf} alt="pdf" />
                                    </a>
                                    <div dangerouslySetInnerHTML={{__html: `${element.case_content_slider}`}} />
                                </div>
                            )}
                            </Slider>
                        </div>
                        <div className="sliderIndex">
                            <div className="sliderIndexContent">
                                <button type="button" data-role="none" className="slick-button slicks-prev" onClick={() => customSlider.current.slickPrev()}> Prev</button>
                                <p>0{casesIndex} / </p> <p style={{color: '#000'}}>0{sliderElements.length}</p>
                                <button type="button" data-role="none" className="slick-button slicks-next" onClick={() => customSlider.current.slickNext()}> Next</button>
                            </div>
                        </div>
                    </> 
                </div>: 
                <div className="serviceFloating scrollable">
                    <>
                        <div className="serviceFloatingContent">
                            <Slider {...settings} ref={slider => (customSlider.current = slider)}>
                            {sliderElements.map((element, i) => 
                                <div key={i} className="case">
                                    <div dangerouslySetInnerHTML={{__html: `${element.page_sliders}`}} />
                                    {/* <p>{element.page_sliders}</p> */}
                                </div>
                            )}
                            </Slider>
                        </div>
                        <div className="sliderIndex">
                            <div className="sliderIndexContent">
                                <button type="button" data-role="none" className="slick-button slicks-prev" onClick={() => customSlider.current.slickPrev()}> Prev</button>
                                <p>0{index} / </p> <p style={{color: '#000'}}>0{sliderElements.length}</p>
                                <button type="button" data-role="none" className="slick-button slicks-next" onClick={() => customSlider.current.slickNext()}> Next</button>
                            </div>
                        </div>
                    </>
                </div>
                }
        </Tilt>
        )
    return ( 
        <React.Fragment>
            <div className="serviceOverlay" style={{zIndex: zIndex}}></div>
            <div className="Service">
                <div className="container">
                    <div className="serviceNav">
                        <li className="serviceNavItem" id="li-who" onClick={onClickServices}>
                            {titles[0]}
                            <div id="who" style={{opacity: props.displayService}}></div>
                        </li>
                        <li className="serviceNavItem" id="li-what" onClick={onClickCases}>
                            {titles[1]}
                            <div id="what" style={{opacity: props.displayCase}}></div>
                        </li>
                        <li className="serviceNavItem" id="li-how" onClick={onClickVideos}>
                            {titles[2]}
                            <div id="how" style={{opacity: props.displayVideo}}></div>
                        </li>
                    </div>
                    <div className="serviceContentContainer">
                        <div className="serviceContent">
                            <div className="serviceContentLeft">
                                <h2 style={{left: props.pos}}>{props.title}</h2>
                                <h4 style={{left: props.pos, opacity: props.display}}>{props.subtitle}</h4>
                                <div className="serviceImage" style={{width: props.width, opacity: props.display}}>
                                    <img src={props.leftImg} alt="who" />
                                </div>
                            </div>
                            <div className="serviceContentRight" style={{opacity: props.display}}>
                                <div className="serviceImageRight">
                                    <img src={props.rightImg} alt="who" style={{width: props.rightWidth}}/>
                                    <div className="imgOverlay" style={{width: props.overlayWidth}} ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="serviceFloatingContainer" style={{opacity: props.opacity}} >
                        {content}
                        <div class="contentOverlay"></div>
                    </div>
                </div>
            </div>
        </React.Fragment>
     );
}

const mapStateToProps = state =>{
    return {
        pos: state.pos,
        width: state.width,
        rightWidth: state.rightWidth,
        overlayWidth: state.overlayWidth,
        opacity: state.opacity,
        title: state.title1,
        subtitle: state.subtitle1,
        desc: state.desc1,
        descCases: state.desc2,
        popup: state.popup,
        leftImg: state.leftImg1,
        rightImg: state.rightImg1,
        displayService: state.displayService,
        displayCase: state.displayCase,
        displayVideo: state.displayVideo,
        videos: state.videos,
        display: state.display
      }
}

const mapDispatchToProps = dispatch =>{
    return {
        onClick: ()=>dispatch(actions.onClickInitial()),
        onClickLater: ()=>dispatch(actions.onClickLater()),
        onClickServices: ()=>dispatch(actions.onClickServices()),
        onClickCases: ()=>dispatch(actions.onClickCases()),
        onClickVideos: ()=>dispatch(actions.onClickVideos()),
        setDisplayIn: ()=>dispatch(actions.setDisplayIn()),
        setDisplayOut: ()=>dispatch(actions.setDisplayOut())
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(Service);