import React, { useEffect, useState } from 'react';
import './About.scss';
import Tilt from '../TiltComponent/Tilt';
import {connect } from 'react-redux'; 
import * as actions from '../../store/actions/actions';

const options = {
    max: 10,
    perspective: 1000,
    scale: 1,
  }

const About = (props) => {
    const titles = ['WHO', 'WHAT', 'HOW'];
    const { init } = props;
    const [transform, setTransform] = useState('');

    useEffect(() => {
        init()
    }, [init])

    useEffect(() => {
        if(window.innerWidth < 1300) {
            setTransform('none !important');
        }

    }, [])

    const onClickWhoHandler = () => {
        props.onClick()
        props.onClickWho()
        setTimeout(() => {
            props.onClickLater()
          }, 1000);
    }

    const onClickWhatHandler = () => {
        props.onClick()
        props.onClickWhat()
        setTimeout(() => {
            props.onClickLater()
          }, 1000);
    }

    const onClickHowHandler = () => {
        props.onClick()
        props.onClickHow()
        setTimeout(() => {
            props.onClickLater()
          }, 1000);
    }

    return ( 
        <div className="About">
            <div className="container">
                <div className="aboutNav">
                    <li className="aboutNavItem" id="li-who" onClick={onClickWhoHandler}>
                        {titles[0]}
                        <div id="who" style={{opacity: props.displayWho}}></div>
                    </li>
                    <li className="aboutNavItem" id="li-what" onClick={onClickWhatHandler}>
                        {titles[1]}
                        <div id="what" style={{opacity: props.displayWhat}}></div>
                    </li>
                    <li className="aboutNavItem" id="li-how" onClick={onClickHowHandler}>
                        {titles[2]}
                        <div id="how" style={{opacity: props.displayHow}}></div>
                    </li>
                </div>
                <div className="aboutContentContainer">
                    <div className="aboutContent">
                        <div className="aboutContentLeft">
                            <h2 style={{left: props.pos}} className={props.class}>{props.title}</h2>
                            <h4 style={{left: props.pos}}>{props.subtitle}</h4>
                            <div className="aboutImage" style={{width: props.width}}>
                                <img src={props.leftImg} alt="who" />
                            </div>
                        </div>
                        <div className="aboutContentRight">
                            <div className="aboutImageRight">
                                <img src={props.rightImg} alt="who" style={{width: props.rightWidth}}/>
                                <div className="imgOverlay" style={{width: props.overlayWidth}} ></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="aboutFloatingContainer" style={{opacity: props.opacity}} >
                    <Tilt
                        options={options}
                        transform={transform}
                    >
                        <div className="aboutFloating scrollable">
                            <div dangerouslySetInnerHTML={{__html: `${props.desc}`}} />
                            {/* <p>{props.desc}</p> */}
                        </div>
                    </Tilt>
                    <div class="contentOverlay"></div>
                </div>
            </div>
        </div>
     );
}

const mapStateToProps = state =>{
    return {
      pos: state.pos,
      width: state.width,
      rightWidth: state.rightWidth,
      overlayWidth: state.overlayWidth,
      opacity: state.opacity,
      title: state.title,
      subtitle: state.subtitle,
      desc: state.desc,
      leftImg: state.leftImg,
      rightImg: state.rightImg,
      displayWho: state.displayWho,
      displayWhat: state.displayWhat,
      displayHow: state.displayHow
    }
}

const mapDispatchToProps = dispatch =>{
    return {
        onClick: ()=>dispatch(actions.onClickInitial()),
        onClickLater: ()=>dispatch(actions.onClickLater()),
        onClickWho: ()=>dispatch(actions.onClickWho()),
        onClickWhat: ()=>dispatch(actions.onClickWhat()),
        onClickHow: ()=>dispatch(actions.onClickHow()),
        init: ()=>dispatch(actions.init()),
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(About);