import React from 'react';
import './Talk.scss';
import {connect } from 'react-redux'; 
import * as actions from '../../store/actions/actions';
import img from '../../assets/images/talk-logo.gif';

const Talk = (props) => {

    const onClickHowHandler = () => {
        props.onClick()
        props.onClickHow()
        props.fullPageAPI.moveTo(2, 0)
        setTimeout(() => {
            props.onClickLater()
          }, 1000);
    }

    const onClickWhoHandler = () => {
        props.onClick()
        props.onClickWho()
        props.fullPageAPI.moveTo(2, 0)
        setTimeout(() => {
            props.onClickLater()
          }, 1000);
    }

    const onClickWhatHandler = () => {
        props.onClick()
        props.onClickWhat()
        props.fullPageAPI.moveTo(2, 0)
        setTimeout(() => {
            props.onClickLater()
          }, 1000);
    }

    const onClickCaseHandler = () => {
        props.onClick()
        props.onClickCases()
        props.fullPageAPI.moveTo(3, 0)
        setTimeout(() => {
            props.setDisplayIn()
            props.onClickLater()
          }, 1000);
    }

    const onClickServiceHandler = () => {
        props.onClick()
        props.onClickServices()
        props.fullPageAPI.moveTo(3, 0)
        setTimeout(() => {
            props.setDisplayIn()
            props.onClickLater()
          }, 1000);
    }

    const onClickVideoHandler = () => {
        props.onClick()
        props.onClickVideos()
        props.setDisplayOut()
        props.fullPageAPI.moveTo(3, 0)
        setTimeout(() => {
            props.onClickLater()
          }, 1000);
    }

    return ( 
        <div className="Talk">
            <div className="talkMenuItems">
                <li onClick={onClickWhoHandler}>WHO</li>
                <li onClick={onClickWhatHandler}>WHAT</li>
                <li onClick={onClickHowHandler}>HOW</li>
                <li onClick={onClickServiceHandler}>SERVICES</li>
                <li onClick={onClickCaseHandler}>CASES</li>
                <li onClick={onClickVideoHandler}>VIDEOS</li>
                <li>WE'RE</li>
                <li>ALL</li>
                <li>TALK!</li>
            </div>
            <div className="talkContent">
                <h2>There's an art to <br /><span> Telephone Data Collection </span></h2>
                <h2>Talk to us!</h2>
            </div>
            <div className="talkImage">
                <img src={img} alt="logo"/>
            </div>
        </div>
     );
}
 
const mapDispatchToProps = dispatch =>{
    return {
        onClick: ()=>dispatch(actions.onClickInitial()),
        onClickLater: ()=>dispatch(actions.onClickLater()),
        onClickWho: ()=>dispatch(actions.onClickWho()),
        onClickWhat: ()=>dispatch(actions.onClickWhat()),
        onClickHow: ()=>dispatch(actions.onClickHow()),
        init: ()=>dispatch(actions.init()),
        onClickCases: ()=>dispatch(actions.onClickCases()),
        onClickServices: ()=>dispatch(actions.onClickServices()),
        onClickVideos: ()=>dispatch(actions.onClickVideos()),
        setDisplayIn: ()=>dispatch(actions.setDisplayIn()),
        setDisplayOut: ()=>dispatch(actions.setDisplayOut())
    }
}
 
export default connect('', mapDispatchToProps)(Talk);