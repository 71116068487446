import * as actionTypes from './actionTypes';
import axios from 'axios'

export const init = () => {
    return function(dispatch) {
      axios.get("https://www.telepoll.net/wp/wp-json/wp/v2/theme_option")
      .then(res => {
        dispatch({ 
            type: actionTypes.INIT,
            payload: res.data[0].acf
         });
      })
      .catch(err =>
        console.log(err)
      );
    }
}

export const onClickWho = () => {
    return function(dispatch) {
      axios.get("https://www.telepoll.net/wp/wp-json/wp/v2/theme_option")
      .then(res => {
        dispatch({ 
            type: actionTypes.ONCLICKWHO,
            payload: res.data[0].acf
         });
      })
      .catch(err =>
        console.log(err)
      );
    }
}

export const onClickWhat = () => {
    return function(dispatch) {
      axios.get("https://www.telepoll.net/wp/wp-json/wp/v2/theme_option")
      .then(res => {
        dispatch({ 
            type: actionTypes.ONCLICKWHAT,
            payload: res.data[0].acf
         });
      })
      .catch(err =>
        console.log(err)
      );
    }
}

export const onClickHow = () => {
    return function(dispatch) {
      axios.get("https://www.telepoll.net/wp/wp-json/wp/v2/theme_option")
      .then(res => {
        dispatch({ 
            type: actionTypes.ONCLICKHOW,
            payload: res.data[0].acf
         });
      })
      .catch(err =>
        console.log(err)
      );
    }
}

export const onClickServices = () => {
  return function(dispatch) {
    axios.get("https://www.telepoll.net/wp/wp-json/wp/v2/theme_option")
    .then(res => {
      dispatch({ 
          type: actionTypes.ONCLICKSERVICES,
          payload: res.data[0].acf
       });
    })
    .catch(err =>
      console.log(err)
    );
  }
}

export const onClickCases = () => {
  return function(dispatch) {
    axios.get("https://www.telepoll.net/wp/wp-json/wp/v2/theme_option")
    .then(res => {
      dispatch({ 
          type: actionTypes.ONCLICKCASES,
          payload: res.data[0].acf
       });
    })
    .catch(err =>
      console.log(err)
    );
  }
}

export const onClickVideos = () => {
  return function(dispatch) {
    axios.get("https://www.telepoll.net/wp/wp-json/wp/v2/theme_option")
    .then(res => {
      dispatch({ 
          type: actionTypes.ONCLICKVIDEOS,
          payload: res.data[0].acf
       });
    })
    .catch(err =>
      console.log(err)
    );
  }
}

export const onClickInitial = () => {
    return {
        type: actionTypes.ONCLICKINITIAL
    }
}

export const onClickLater = () => {
    return {
        type: actionTypes.ONCLICKLATER
    }
}

export const setDisplayIn = () => {
  return {
      type: actionTypes.SETDISPLAYIN
  }
}

export const setDisplayOut = () => {
  return {
      type: actionTypes.SETDISPLAYOUT
  }
}