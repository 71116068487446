import React, { useState, useEffect } from 'react';
import logo from '../../assets/images/logo.png';
import './Header.scss';
import { NavLink } from 'react-router-dom';
import {connect } from 'react-redux'; 
import axios from 'axios';
import * as actions from '../../store/actions/actions';

const Header = (props) => {
    const [tollfreeNo, setTollfreeNo] = useState('');
    const [email, setEmail] = useState('');

    useEffect(() => {
        let unmounted = false;

        axios.get("https://www.telepoll.net/wp/wp-json/wp/v2/theme_option")
        .then(res => {
            if (!unmounted) {
                setTollfreeNo(res.data[0].acf['toll_free_contact_number'])
                setEmail(res.data[0].acf['email_id'])
            }
        })
        .catch(err => {
            if (!unmounted) {
                console.log(err);
              }
            }
        );
        return () => { unmounted = true };
    }, [])

    const onClickHowHandler = () => {
        props.onClick()
        props.onClickHow()
        setTimeout(() => {
            props.onClickLater()
          }, 1000);
    }

    const onClickWhoHandler = () => {
        props.onClick()
        props.onClickWho()
        setTimeout(() => {
            props.onClickLater()
          }, 1000);
    }

    const onClickCaseHandler = () => {
        props.onClick()
        props.onClickCases()
        setTimeout(() => {
            props.setDisplayIn()
            props.onClickLater()
          }, 1000);
    }

    const onClickServiceHandler = () => {
        props.onClick()
        props.onClickServices()
        setTimeout(() => {
            props.setDisplayIn()
            props.onClickLater()
          }, 1000);
    }

    return ( 
        <React.Fragment>
            <header className="header-bar">
                <div className="container">
                    <div className="brand">
                        <NavLink to='/'><img src={logo} alt="logo"/></NavLink> 
                    </div>
                    <div className="navbar-menu"> 
                        {window.location.pathname === "/" ? 
                        <ul>
                            <button onClick={onClickWhoHandler}><li><a href="#sectionTwo">Who</a></li></button>
                            <button onClick={onClickHowHandler}><li><a href="#sectionTwo">How</a></li></button>
                            <button onClick={onClickServiceHandler}><li><a href="#sectionThree">Services</a></li></button>
                            <button onClick={onClickCaseHandler}><li><a href="#sectionThree">Case Studies</a></li></button>
                            <button><li><a href="#sectionFive">Testimonials</a></li></button>
                        </ul> :
                        <ul>
                            <button><li><NavLink to='/'>Home</NavLink> </li></button>
                            <button><li><NavLink to='/privacy-policy'>Privacy Policy</NavLink></li></button>
                            <button><li><NavLink to='/terms-and-conditions'>Terms and Conditions</NavLink></li></button>
                        </ul> }
                    </div>
                    <div className="contact">
                        <div className="contact-detail">
                            <a href={`tel:${tollfreeNo}`} id="phone">{tollfreeNo}</a>
                        </div>
                        <div className="contact-detail">
                            <a href={`mailto:${email}`} id="mail">{email}</a>
                        </div>
                    </div>
                </div>
            </header>
        </React.Fragment>
     );
}

const mapStateToProps = state =>{
    return {
      pos: state.pos,
      width: state.width,
      rightWidth: state.rightWidth,
      overlayWidth: state.overlayWidth,
      opacity: state.opacity,
      title: state.title,
      subtitle: state.subtitle,
      desc: state.desc,
      leftImg: state.leftImg,
      rightImg: state.rightImg,
      displayWho: state.displayWho,
      displayWhat: state.displayWhat,
      displayHow: state.displayHow
    }
}

const mapDispatchToProps = dispatch =>{
    return {
        onClick: ()=>dispatch(actions.onClickInitial()),
        onClickLater: ()=>dispatch(actions.onClickLater()),
        onClickWho: ()=>dispatch(actions.onClickWho()),
        onClickWhat: ()=>dispatch(actions.onClickWhat()),
        onClickHow: ()=>dispatch(actions.onClickHow()),
        init: ()=>dispatch(actions.init()),
        onClickCases: ()=>dispatch(actions.onClickCases()),
        onClickServices: ()=>dispatch(actions.onClickServices()),
        setDisplayIn: ()=>dispatch(actions.setDisplayIn())
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(Header);