import React from 'react';
import './Terms.scss';
import Tilt from '../TiltComponent/Tilt';
import Header from '../Header/Header';
import Contact from '../Contact/Contact';
import Footer from '../Footer/Footer';
import ReactFullpage from '@fullpage/react-fullpage';

const options = {
    max: 10,
    perspective: 1000,
    scale: 1,
  }

const Terms = (props) => (
    <ReactFullpage
      //fullpage options
      licenseKey = {'E8EB3363-1DB64EB9-8573734A-9DDF1361'}
      scrollingSpeed = {1000} /* Options here */
      normalScrollElements = {'.scrollable'}
      responsiveWidth = {1200}
  
      render={({ state, fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>
            <Header />
            <div className="section">
                <div className="Terms">
                    <div className="container">
                        <div className="termsContentContainer">
                            <div className="termsContent">
                                <div className="termsContentLeft">
                                    <h2>{props.title}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="termsFloatingContainer" >
                            <Tilt
                                options={options}
                            >
                                <div className="termsFloating scrollable">
                                    <p>{props.content}</p>
                                </div>
                            </Tilt>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section fp-auto-height">
                <Contact />
            </div>
            <div className="section fp-auto-height">
                <Footer />
            </div>
        </ReactFullpage.Wrapper>
      );
    }}
  />
);
 
export default Terms;