import * as actionTypes from '../actions/actionTypes';

const initialState = {
    pos: '-1000px',
    width: '0',
    rightWidth: '0',
    overlayWidth: '0',
    opacity: '0',
    title: '',
    subtitle: '',
    desc: '',
    leftImg: '',
    rightImg: '',
    displayWho: '1',
    displayWhat: '0',
    displayHow: '0',
    title1: '',
    subtitle1: '',
    desc1: [],
    desc2: [],
    popup: [],
    leftImg1: '',
    rightImg1: '',
    displayService: '1',
    displayCase: '0',
    displayVideo: '0',
    videos: '',
    display: '1'
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.ONCLICKINITIAL:
            return {
                ...state,
                pos: '-1000px',
                width: '0',
                rightWidth: '0',
                overlayWidth: '0',
                opacity: '0'
            }
        case actionTypes.ONCLICKLATER:
            return {
                ...state,
                pos: '0',
                width: '75%',
                rightWidth: '100%',
                overlayWidth: '120px',
                opacity: '1'
            }
        case actionTypes.SETDISPLAYOUT:
            return {
                ...state,
                display: '0'
            }
        case actionTypes.SETDISPLAYIN:
            return {
                ...state,
                display: '1'
            }
        case actionTypes.INIT:
            return {
                ...state,
                title: action.payload['whotitle'],
                subtitle: action.payload['whosubtitle'],
                desc: action.payload['whopara'],
                leftImg: action.payload['wholeft_image'],
                rightImg: action.payload['whoright_image'],
                title1: action.payload['sertitle'],
                subtitle1: action.payload['sersubtitle'],
                desc1: action.payload['serpara'],
                desc2: action.payload['casepara'],
                leftImg1: action.payload['serleft_image'],
                rightImg1: action.payload['serright_image'],
                pos: '0',
                width: '75%',
                rightWidth: '100%',
                overlayWidth: '120px',
                opacity: '1',
                displayWho: '1',
                displayWhat: '0',
                displayHow: '0',
                videos: action.payload['vdeovideo_slider'][0].video
            }
        case actionTypes.ONCLICKWHO:
            return {
                ...state,
                title: action.payload['whotitle'],
                subtitle: action.payload['whosubtitle'],
                desc: action.payload['whopara'],
                leftImg: action.payload['wholeft_image'],
                rightImg: action.payload['whoright_image'],
                displayWho: '1',
                displayWhat: '0',
                displayHow: '0'
            }
        case actionTypes.ONCLICKWHAT:
            return {
                ...state,
                title: action.payload['whattitle'],
                subtitle: action.payload['whatsubtitle'],
                desc: action.payload['whatpara'],
                leftImg: action.payload['whatleft_image'],
                rightImg: action.payload['whatright_image'],
                displayWho: '0',
                displayWhat: '1',
                displayHow: '0'
            }
        case actionTypes.ONCLICKHOW:
            return {
                ...state,
                title: action.payload['howtitle'],
                subtitle: action.payload['howsubtitle'],
                desc: action.payload['howpara'],
                leftImg: action.payload['howleft_image'],
                rightImg: action.payload['howright_image'],
                displayWho: '0',
                displayWhat: '0',
                displayHow: '1'
            }
        case actionTypes.ONCLICKSERVICES:
            return {
                ...state,
                title1: action.payload['sertitle'],
                subtitle1: action.payload['sersubtitle'],
                desc1: action.payload['serpara'],
                leftImg1: action.payload['serleft_image'],
                rightImg1: action.payload['serright_image'],
                displayService: '1',
                displayCase: '0',
                displayVideo: '0'
            }
        case actionTypes.ONCLICKCASES:
            return {
                ...state,
                title1: action.payload['casetitle'],
                subtitle1: action.payload['casesubtitle'],
                desc2: action.payload['casepara'],
                popup: action.payload['casepara_popup'],
                leftImg1: action.payload['caseleft_image'],
                rightImg1: action.payload['caseright_image'],
                displayService: '0',
                displayCase: '1',
                displayVideo: '0'
            }
        case actionTypes.ONCLICKVIDEOS:
            return {
                ...state,
                title1: action.payload['vdeotitle'],
                subtitle1: '',
                desc1: '',
                desc2: '',
                leftImg1: '',
                rightImg1: '',
                videos: action.payload['vdeovideo_slider'],
                displayService: '0',
                displayCase: '0',
                displayVideo: '1'
            }
        default:
            return state
    }
}

export default reducer;