import React, { useState } from 'react';
import logo from '../../assets/images/logo.png';
import { NavLink } from 'react-router-dom';
import {connect } from 'react-redux'; 
import * as actions from '../../store/actions/actions';

const Header = (props) => {
    const [bg, setBg] = useState('none');
    const [transform, setTransform] = useState('');
    const [checked, setChecked] = useState(false);
    const [navTransform, setNavTransform] = useState('translateX(-50%)');
    const [opacity, setOpacity] = useState('0');
    const [visibility, setVisibility] = useState('hidden');
    const [zindex, setZindex] = useState('0');
    const [transform1, setTransform1] = useState('translateY(-10px)');
    const [transform2, setTransform2] = useState('translateY(-10px)');
    const leftodd = '0';
    const [left3, setLeft3] = useState('0');
    const [opacity3, setOpacity3] = useState('1');
    const bradiusodd = '0.9rem 0 0 0.9rem';
    const lefteven = '50%';
    const [left4, setLeft4] = useState('50%');
    const bradiuseven = '0 0.9rem 0.9rem 0';
    const [transform5, setTransform5] = useState('translateY(10px)');
    const [transform6, setTransform6] = useState('translateY(10px)');

    const handleCheck = () => {
        setChecked(!checked);
        if(!checked) {
            setBg('radial-gradient(#0d77bd,#0f82cf)');
            setTransform('scale(100)');
            setNavTransform('translateX(0)');
            setOpacity('1');
            setVisibility('initial');
            setZindex('10');
            setTransform1('rotate(45deg)');
            setTransform2('rotate(-45deg)');
            setLeft3('-50%');
            setOpacity3('0');
            setLeft4('100%');
            setTransform5('rotate(-45deg)');
            setTransform6('rotate(45deg)');
        }
        else {
            setBg('none');
            setTransform('')
            setNavTransform('translateX(-50%)');
            setOpacity('0');
            setVisibility('hidden');
            setZindex('-1');
            setTransform1('translateY(-10px)');
            setTransform2('translateY(-10px)');
            setLeft3('0');
            setOpacity3('1');
            setLeft4('50%');
            setTransform5('translateY(10px)');
            setTransform6('translateY(10px)');
        }
    }

    const onClickHowHandler = () => {
        handleCheck()
        props.onClick()
        props.onClickHow()
        setTimeout(() => {
            props.onClickLater()
          }, 1000);
    }

    const onClickWhoHandler = () => {
        handleCheck()
        props.onClick()
        props.onClickWho()
        setTimeout(() => {
            props.onClickLater()
          }, 1000);
    }

    const onClickCaseHandler = () => {
        handleCheck()
        props.onClick()
        props.onClickCases()
        setTimeout(() => {
            props.setDisplayIn()
            props.onClickLater()
          }, 1000);
    }

    const onClickServiceHandler = () => {
        handleCheck()
        props.onClick()
        props.onClickServices()
        setTimeout(() => {
            props.setDisplayIn()
            props.onClickLater()
          }, 1000);
    }

    const onClickTestimonialHandler = () => {
        handleCheck()
    }

    return ( 
        <React.Fragment>
            <div className="mobile-menu">
                <div className="container">
                    <div className="brand">
                        <NavLink to='/'><img src={logo} alt="logo"/></NavLink> 
                    </div>
                    <div className="navigation">
                        <input type="checkbox" id="navi-toggle" className="navigation__checkbox" onChange={handleCheck}/>
                        <label htmlFor="navi-toggle" className="navigation__button">
                            <span className="navigation__icon">
                                <span className="navigation__icon-span" style={{transform: transform1, left: leftodd, borderRadius: bradiusodd, transformOrigin: 'right'}}>&nbsp;</span>
                                <span className="navigation__icon-span" style={{transform: transform2, left: lefteven, borderRadius: bradiuseven, transformOrigin: 'left'}}>&nbsp;</span>
                                <span className="navigation__icon-span" style={{left: left3, borderRadius: bradiusodd, opacity: opacity3}}>&nbsp;</span>
                                <span className="navigation__icon-span" style={{left: left4, borderRadius: bradiuseven, opacity: opacity3}}>&nbsp;</span>
                                <span className="navigation__icon-span" style={{transform: transform5, left: leftodd, borderRadius: bradiusodd, transformOrigin: 'right'}}>&nbsp;</span>
                                <span className="navigation__icon-span" style={{transform: transform6, left: lefteven, borderRadius: bradiuseven, transformOrigin: 'left'}}>&nbsp;</span>
                            </span>
                        </label>

                        <div className="navigation__background" style={{backgroundImage: bg, transform: transform, zIndex: zindex}}>&nbsp;</div>

                        <nav className="navigation__nav" style={{transform: navTransform, visibility: visibility, opacity: opacity}}>
                            {window.location.pathname === "/" ?
                            <ul className="navigation__list">
                                <li className="navigation__item">
                                    <button onClick={onClickWhoHandler}><li><a className="navigation__link" href="#sectionTwo">Who</a></li></button>
                                </li>
                                <li className="navigation__item">
                                    <button onClick={onClickHowHandler}><li><a className="navigation__link" href="#sectionTwo">How</a></li></button>
                                </li>
                                <li className="navigation__item">
                                    <button onClick={onClickServiceHandler}><li><a className="navigation__link" href="#sectionThree">Services</a></li></button>
                                </li>
                                <li className="navigation__item">
                                    <button onClick={onClickCaseHandler}><li><a className="navigation__link" href="#sectionThree">Case Studies</a></li></button>
                                </li>
                                <li className="navigation__item">
                                    <button onClick={onClickTestimonialHandler}><li><a className="navigation__link" href="#sectionFive">Testimonials</a></li></button>
                                </li>
                            </ul> :
                            <ul className="navigation__list">
                                <li className="navigation__item">
                                    <NavLink  to='/' className="navigation__link" onClick={handleCheck}>Home</NavLink> 
                                </li>
                                <li className="navigation__item">
                                    <NavLink  to='/privacy-policy' className="navigation__link" onClick={handleCheck}>Privacy Policy</NavLink> 
                                </li>
                                <li className="navigation__item">
                                    <NavLink to='/terms-and-conditions' className="navigation__link" onClick={handleCheck}>Terms and Conditions</NavLink> 
                                </li>
                            </ul>
                            }
                        </nav>
                    </div>
                </div>
            </div>
        </React.Fragment>
     );
}

const mapStateToProps = state =>{
    return {
      pos: state.pos,
      width: state.width,
      rightWidth: state.rightWidth,
      overlayWidth: state.overlayWidth,
      opacity: state.opacity,
      title: state.title,
      subtitle: state.subtitle,
      desc: state.desc,
      leftImg: state.leftImg,
      rightImg: state.rightImg,
      displayWho: state.displayWho,
      displayWhat: state.displayWhat,
      displayHow: state.displayHow
    }
}

const mapDispatchToProps = dispatch =>{
    return {
        onClick: ()=>dispatch(actions.onClickInitial()),
        onClickLater: ()=>dispatch(actions.onClickLater()),
        onClickWho: ()=>dispatch(actions.onClickWho()),
        onClickWhat: ()=>dispatch(actions.onClickWhat()),
        onClickHow: ()=>dispatch(actions.onClickHow()),
        init: ()=>dispatch(actions.init()),
        onClickCases: ()=>dispatch(actions.onClickCases()),
        onClickServices: ()=>dispatch(actions.onClickServices()),
        setDisplayIn: ()=>dispatch(actions.setDisplayIn())
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(Header);