import React from 'react';
import './Footer.scss';
import { NavLink } from 'react-router-dom';

const Footer = (props) => {
    return ( 
        <div className="Footer">
            <div className="container">
                <p>Telepoll  ©  2020. All Rights Reserved. <NavLink to='/terms-and-conditions'>Terms & Conditions</NavLink>  | <NavLink to='/privacy-policy'>Privacy Policy</NavLink></p>
            </div>
        </div>
     );
}
 
export default Footer;