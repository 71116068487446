import React from 'react';
import './Home.scss';
import Header from '../Header/Header';
import Banner from '../Banner/Banner';
import About from '../About/About';
import Service from '../Service/Service';
import Talk from '../Talk/Talk';
import Testimonial from '../Testimonial/Testimonial';
import Contact from '../Contact/Contact';
import Footer from '../Footer/Footer';
import ReactFullpage from '@fullpage/react-fullpage';
import '../Header/Header.scss';
 
const App = () => (
  <ReactFullpage
    //fullpage options
    licenseKey = {'E8EB3363-1DB64EB9-8573734A-9DDF1361'}
    scrollingSpeed = {1000} /* Options here */
    normalScrollElements = {'.scrollable'}
    responsiveWidth = {1400}
    anchors = {['sectionOne', 'sectionTwo', 'sectionThree', 'sectionFour', 'sectionFive', 'sectionSix', 'sectionSeven']}

    render={({ state, fullpageApi }) => {
      return (
        <ReactFullpage.Wrapper style={{position: 'relative'}}>
          <Header />
          <div className="section fp-auto-height">
            <Banner />
          </div>
          <div className="section fp-auto-height">
            <About />
            {/* <ViewportBlock /> */}
          </div>
          <div className="section fp-auto-height">
            <Service />
          </div>
          <div className="section fp-auto-height">
            <Talk fullPageAPI={fullpageApi}/>
          </div>
          <div className="section fp-auto-height">
            <Testimonial />
          </div>
          <div className="section fp-auto-height">
            <Contact />
          </div>
          <div className="section fp-auto-height">
            <Footer />
          </div>
        </ReactFullpage.Wrapper>
      );
    }}
  />
);

export default App;